import request from "@/utils/request";
// 新增
export function getTableAdd(data) {
  return request("post", "/apm/popup/create", data);
}
// 删除
export function getTableDel(data) {
  return request("post", "/apm/popup/del", data);
}
// 详情
export function getTableInfo(data) {
  return request("post", "/apm/popup/findById", data);
}

// 列表
export function getTableList(data) {
  return request("post", "/apm/popup/page", data);
}

// 查询用户的弹窗列表
export function getUserPopupList(data) {
  return request("get", "/apm/popup/findPopupByMemberId", data);
}

// 更新弹窗排序
export function updateSort(data) {
  return request("post", "/apm/popup/sort", data);
}

// 编辑
export function getTableEdit(data) {
  return request("post", "/apm/popup/update", data);
}

// 更新弹窗状态
export function updateStatus(data) {
  return request("post", "/apm/popup/updateStatus", data);
}

